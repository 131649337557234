<template>
  <list-layout>
    <template slot="search">
      <b-col cols="4">
        <div class="position-relative">
          <b-form-input v-model="search" placeholder="Search" class="pr-12" @keyup.enter="searchName = search" />
          <font-awesome-icon
            :icon="['fas', 'search']"
            fixed-width
            class="input-search text-primary"
            @click="searchName = search"
          />
        </div>
      </b-col>
    </template>
    <template slot="actions">
      <b-row>
        <b-col cols="6">
          <b-button variant="secondary" class="btn-action text-primary inline" @click="exportData()">
            <font-awesome-icon :icon="['fas', 'download']" fixed-width />
          </b-button>
        </b-col>
        <b-col cols="6">
          <b-button variant="secondary" class="btn-action text-primary inline" :to="{ name: 'ClinicCreate' }">
            <font-awesome-icon :icon="['fas', 'plus']" fixed-width />
          </b-button>
        </b-col>
      </b-row>
    </template>
    <template slot="list">
      <b-table
        hover
        borderless
        :items="sortClinicList"
        :current-page="currentPage"
        :per-page="rowPrePage"
        :fields="fields"
        @row-clicked="onClinicSelected"
      >
        <template #head(name)>
          <div class="cursor-pointer" @click="sortList('name')">
            NAME
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>
        <template #head(status)>
          <div class="cursor-pointer" @click="sortList('status')">
            STATUS
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>
        <template #head(location)>
          <div class="cursor-pointer" @click="sortList('location')">
            CITY
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>
        <template #head(contact)>
          <div class="cursor-pointer" @click="sortList('contact')">
            CONTACT
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>
        <template #head(telephone)>
          <div class="cursor-pointer" @click="sortList('telephone')">
            TELEPHONE
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>

        <template #cell(name)="data">
          {{ data.item.name }}
        </template>

        <template #cell(status)="data">
          <span v-if="data.item.status === 0" class="d-inline-block bg-primary text-white rounded-pill px-2 py-0">
            Inactive
          </span>
          <span v-else-if="data.item.status===-1" class="d-inline-block bg-secondary text-white rounded-pill px-2 py-0">
            Terminate
          </span>
          <span v-else class="d-inline-block bg-success text-white rounded-pill px-2 py-0">
            Active
          </span>
        </template>
      </b-table>
      <div class="d-flex justify-content-between mt-6">
        <div />
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="6"
          align="fill"
          size="sm"
          class="my-0"
          first-number
          last-number
        />
        <row-count :total-rows="totalRows" :row-pre-page="rowPrePage" :current-page="currentPage" />
      </div>
    </template>
  </list-layout>
</template>
<script>
import listLayout from '@/components/layouts/listLayout'
import countryList from '@/common/countryList'
import { getClinicList } from '@/api/clinic'
import RowCount from '@/components/common/rowCount'
import * as XLSX from 'xlsx'

export default {
  name: 'Clinic',
  components: {
    listLayout,
    RowCount
  },
  data () {
    return {
      search: '',

      searchName: '',
      region: null,
      field: 'name',
      ascending: true,

      clinicList: [],
      totalRows: 0,
      currentPage: 1,
      rowPrePage: 6
    }
  },
  computed: {
    currentPageCnt () {
      const currentpageCnt = this.rowPrePage * this.currentPage
      if (currentpageCnt > this.totalRows) {
        const res = this.totalRows % this.rowPrePage
        if (res === 1) { return this.totalRows }
        return (this.totalRows - res + 1) + ' - ' + this.totalRows
      }
      return (currentpageCnt - this.rowPrePage + 1) + ' - ' + currentpageCnt
    },
    countryList () {
      return countryList
    },
    fields () {
      return ['name', 'contact', 'telephone', 'location', 'status']
    },
    filterClinicList () {
      return this.clinicList.reduce((list, clinic) => {
        if (this.searchName && clinic.clinic_name.indexOf(this.searchName) === -1) {
          this.totalRows = list.length
          return list
        }
        if (this.region && clinic.clinic_country !== this.region) {
          this.totalRows = list.length
          return list
        }

        const country = this.countryList.find(c => c.value === clinic.clinic_country)
        list.push({
          id: clinic.id,
          name: clinic.clinic_name,
          status: clinic.status,
          location: `${clinic.clinic_city}, ${country?.text}`,
          contact: clinic.contact_name,
          telephone: clinic.clinic_telphone
        })
        this.totalRows = list.length
        return list
      }, [])
    },
    sortClinicList () {
      return this.filterClinicList.slice().sort((a, b) => {
        const dataA = String(a[this.field]).toLowerCase()
        const dataB = String(b[this.field]).toLowerCase()
        if (dataA > dataB) return this.ascending ? 1 : -1
        if (dataA < dataB) return this.ascending ? -1 : 1
        else return 0
      })
    }
  },
  created () {
    this.getClinicList()
  },
  methods: {
    getClinicList () {
      getClinicList().then(res => {
        this.clinicList = res.data.data
      })
    },
    sortList (field) {
      if (this.field === field) {
        this.ascending = !this.ascending
      } else {
        this.field = field
        this.ascending = true
      }
    },
    onClinicSelected (item) {
      this.$router.push({ name: 'ClinicDetail', params: { id: item.id } })
    },
    exportData () {
      const cliniclist = this.clinicList.filter(item => {
        if (this.searchName) {
          return item.clinic_name.indexOf(this.searchName) > 0
        } else {
          return true
        }
      }).map(item => {
        const country = this.countryList.find(c => c.value === item.clinic_country)
        return [
          item.clinic_name,
          item.contact_name,
          item.clinic_telphone,
          `${item.clinic_city}, ${country?.text}`,
          item.status === 1 ? 'Active' : 'Inactive'
        ]
      })
      const wsData = [
        ['Name', 'Contact', 'Telephone', 'City', 'Status'],
        ...cliniclist
      ]
      var wscols = [{ width: 20 }, { width: 20 }, { width: 20 }, { width: 30 }, { width: 10 }]

      const workbook = XLSX.utils.book_new()
      const sheet1 = XLSX.utils.aoa_to_sheet(wsData)
      sheet1['!cols'] = wscols
      XLSX.utils.book_append_sheet(workbook, sheet1, 'clinic list')
      XLSX.writeFile(workbook, 'clinic.xlsx')
    }
  }
}
</script>
